import {
  PRODUCT_BROADBAND,
  PRODUCT_ENERGY,
  PRODUCT_INSURANCE,
  PRODUCT_MOBILE,
  PRODUCT_MOBILE_2,
} from 'redux/modules/Bundles/constants';

import {
  ADD_ENERGY_FIXED_OR_20,
  ANOTHER_AND_ENERGY_FIXED_OR_50,
  EARLY_TERMINATION,
  ADD_ANOTHER_FIXED_OR_20,
  ADD_ENERGY_FIXED_OR_50,
  ADD_ONE_FIXED_OR_50,
  ADD_TWO_FIXED_OR_50,
  TBYB_BROADBAND,
  TEMPLATED_ENERGY,
  ADD_TWO_MOBILE_EARLY_TERMINATION,
  ADD_TWO_MOBILE_CHEAPEST_FIXED,
} from './benefits';
import {
  addWhen,
  buildLockedSaving as buildLockedSavingUnwrapped,
} from './utils';

export const buildSummarySavings = (fixedQuotesSummary, isPrepayment) => {
  const buildLockedSaving = buildLockedSavingUnwrapped(fixedQuotesSummary);

  return [
    {
      serviceIds: [PRODUCT_BROADBAND, PRODUCT_MOBILE, PRODUCT_INSURANCE],
      messages: {
        locked: [buildLockedSaving(ADD_ENERGY_FIXED_OR_50, [PRODUCT_ENERGY])],
      },
    },
    {
      serviceIds: [PRODUCT_BROADBAND, PRODUCT_MOBILE, PRODUCT_MOBILE_2],
      messages: {
        locked: [buildLockedSaving(ADD_ENERGY_FIXED_OR_50, [PRODUCT_ENERGY])],
      },
    },
    {
      serviceIds: [PRODUCT_INSURANCE, PRODUCT_MOBILE, PRODUCT_MOBILE_2],
      messages: {
        locked: [buildLockedSaving(ADD_ENERGY_FIXED_OR_50, [PRODUCT_ENERGY])],
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_BROADBAND],
      messages: {
        locked: [
          ...addWhen(
            !isPrepayment,
            buildLockedSaving(
              fixedQuotesSummary ? TEMPLATED_ENERGY : ADD_ONE_FIXED_OR_50,
              [PRODUCT_MOBILE, PRODUCT_INSURANCE],
              true,
              true
            )
          ),
          buildLockedSaving(EARLY_TERMINATION, [
            PRODUCT_MOBILE,
            PRODUCT_INSURANCE,
          ]),
        ],
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_MOBILE],
      messages: {
        locked: [
          ...addWhen(
            !isPrepayment,
            buildLockedSaving(
              fixedQuotesSummary ? TEMPLATED_ENERGY : ADD_ONE_FIXED_OR_50,
              [PRODUCT_BROADBAND, PRODUCT_INSURANCE, PRODUCT_MOBILE_2],
              true,
              true
            )
          ),
          buildLockedSaving(EARLY_TERMINATION, [
            PRODUCT_BROADBAND,
            PRODUCT_INSURANCE,
            PRODUCT_MOBILE_2,
          ]),
        ],
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY, PRODUCT_INSURANCE],
      messages: {
        locked: [
          ...addWhen(
            !isPrepayment,
            buildLockedSaving(
              fixedQuotesSummary ? TEMPLATED_ENERGY : ADD_ONE_FIXED_OR_50,
              [PRODUCT_BROADBAND, PRODUCT_MOBILE],
              true,
              true
            )
          ),
          buildLockedSaving(EARLY_TERMINATION, [
            PRODUCT_BROADBAND,
            PRODUCT_MOBILE,
          ]),
        ],
      },
    },
    {
      serviceIds: [PRODUCT_BROADBAND, PRODUCT_MOBILE],
      messages: {
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50, [PRODUCT_ENERGY]),
          buildLockedSaving(EARLY_TERMINATION, [
            PRODUCT_ENERGY,
            PRODUCT_INSURANCE,
            PRODUCT_MOBILE_2,
          ]),
        ],
      },
    },
    {
      serviceIds: [PRODUCT_BROADBAND, PRODUCT_INSURANCE],
      messages: {
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50, [PRODUCT_ENERGY]),
          buildLockedSaving(EARLY_TERMINATION, [
            PRODUCT_ENERGY,
            PRODUCT_MOBILE,
          ]),
        ],
      },
    },
    {
      serviceIds: [PRODUCT_MOBILE, PRODUCT_INSURANCE],
      messages: {
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_50, [PRODUCT_ENERGY]),
          buildLockedSaving(EARLY_TERMINATION, [
            PRODUCT_ENERGY,
            PRODUCT_BROADBAND,
            PRODUCT_MOBILE_2,
          ]),
        ],
      },
    },
    {
      serviceIds: [PRODUCT_ENERGY],
      messages: {
        locked: [
          ...addWhen(
            !isPrepayment,
            buildLockedSaving(
              fixedQuotesSummary ? TEMPLATED_ENERGY : ADD_ANOTHER_FIXED_OR_20,
              [PRODUCT_BROADBAND, PRODUCT_MOBILE, PRODUCT_INSURANCE]
            )
          ),
          buildLockedSaving(ADD_TWO_FIXED_OR_50, [
            PRODUCT_BROADBAND,
            PRODUCT_INSURANCE,
          ]),
          buildLockedSaving(ADD_TWO_MOBILE_CHEAPEST_FIXED, [PRODUCT_MOBILE]),
          buildLockedSaving(ADD_TWO_MOBILE_EARLY_TERMINATION, [PRODUCT_MOBILE]),
        ],
      },
    },
    {
      serviceIds: [PRODUCT_BROADBAND],
      messages: {
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_20, [PRODUCT_ENERGY]),
          buildLockedSaving(ANOTHER_AND_ENERGY_FIXED_OR_50, [
            PRODUCT_ENERGY,
            PRODUCT_INSURANCE,
            PRODUCT_MOBILE,
          ]),
          buildLockedSaving(ADD_TWO_MOBILE_CHEAPEST_FIXED, [PRODUCT_MOBILE]),
          buildLockedSaving(ADD_TWO_MOBILE_EARLY_TERMINATION, [PRODUCT_MOBILE]),
          buildLockedSaving(TBYB_BROADBAND, [
            PRODUCT_ENERGY,
            PRODUCT_INSURANCE,
            PRODUCT_MOBILE,
          ]),
        ],
      },
    },
    {
      serviceIds: [PRODUCT_MOBILE],
      messages: {
        locked: [
          buildLockedSaving(ADD_ENERGY_FIXED_OR_20, [PRODUCT_ENERGY]),
          buildLockedSaving(ANOTHER_AND_ENERGY_FIXED_OR_50, [
            PRODUCT_ENERGY,
            PRODUCT_INSURANCE,
            PRODUCT_MOBILE_2,
            PRODUCT_BROADBAND,
          ]),
        ],
      },
    },
  ];
};
